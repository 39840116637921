footer {
  background-color: var(--color-primary);
  padding: 2rem 0;
  padding-bottom: 6rem;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

footer a {
  color: var(--color-bg);
}

.footer__logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 2.5rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  font-size: 120%;
}

.footer__socials a {
  background-color: var(--color-bg);
  color: var(--color-white);
  padding: 0.6rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
  background-color: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer_copyrights {
  margin: 0;
  color: var(--color-bg);
}

/*  ---- media queries ----- */

/* small devices */
@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1.5rem;
  }
  .footer__socials {
    margin-bottom: 2.5rem;
  }
}
