header {
  height: 100vh;
  padding-top: 1.5rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background-color: var(--color-primary);
}

.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 27rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 3rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 1rem 1.8rem 1.5rem 1.3rem;
}

.scroll__down {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}
.scroll__down::before {
  /* transform: rotateX(90deg); */
  content: "";
  width: 1.5px;
  height: 2rem;
  background-color: var(--color-primary);
  transform: rotate(90deg);
}

/*  ---- media queries ----- */
/* medium devices */
@media screen and (max-width: 1024px) {
  header {
    height: 90vh;
  }
  .me {
    width: 20rem;
    height: 24rem;
    position: absolute;
    left: calc(50% - 10rem);
    margin-top: 2.5rem;
  }
}

/* small devices */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
}
